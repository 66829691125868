
// Import
if (typeof module !== 'undefined' && module.exports)
{
	// This module is shared by the Medusa front-end, and thus needs to be guarded
	var jQuery = require ('striata-jquery');
	var $ = jQuery;
	var HideSystemErrors = require ('@control/error/systemerror').HideSystemErrors;
	var HighestZIndex = require ('@utility/utility').HighestZIndex;
	require ('./modal.css');
}


var resizeTimer;

// Initialize the active modal stack
var modalList = [];


$(window).on ('resize', function()
{
	$(window).ResetModal();
});


$(function()
{
	$('body').append ('<div id="backgroundPopup" name="backgroundPopup" class="modalBackgroundPopup" style="display:none"></div>');
});


function GetScrollHeight()
{
	var h = window.pageYOffset ||
		document.body.scrollTop ||
		document.documentElement.scrollTop;

	return h ? h : 0;
}



$.fn.ResetModal = function()
{
	if (modalList.length > 0)
	{
		// Here we know a modal is on the page move it
		var divModal = modalList[modalList.length-1];
		var windowWidth = $(window).width();
		var windowHeight = $(window).height();
		var popupHeight = $(divModal).height();
		var popupWidth = $(divModal).width();
		var middle = windowHeight / 2 ;
		var middleoflocation = middle + GetScrollHeight();
		var topPosition = (middleoflocation) - (popupHeight/2);

		$(divModal).addClass('modalDivParent');

		$(divModal).css (
		{
			"top"  : topPosition < 0 ? 0 : topPosition,
			"left" : (windowWidth/2) - (popupWidth/2)
		});
	}
};



$.fn.ResetModalBackGround = function()
{
	$("#backgroundPopup").addClass ('modalBackgroundPopup');
	$(".modalBackgroundPopup").css ("height", $(".modalBackgroundPopup").css ("min-height"));
	$(".modalBackgroundPopup").css ("height", $(document).height() + "px");
};




$.fn.Modal = function (divModal, closeId, options)
{
	// First check if visible
	if ($(divActiveModal).is(":visible"))
	{
		return false;
	}

	HideSystemErrors (divModal);

	// Add current modal ID to the stack of active modals
	modalList.push (divModal);
	var divActiveModal = modalList[modalList.length - 1];

	// Initialize variables that are used by the modal
	var defaults = { position: "top", offsetTops:0 , offsetSides:0, transitionSpeed:"fast", defaultInputElement: 0, persistOnClose: false};
	if (!options)
	{
		options = {};
	}
	var modalOptions = $.extend (defaults, options);
	var scrollHeight = document.documentElement.scrollHeight;
	var windowWidth = $(window).width();
	var windowHeight = $(window).height();
	var popupHeight = $(divActiveModal).height();
	var popupWidth = $(divActiveModal).width();
	var middle = windowHeight / 2 ;
	var middleoflocation = middle + GetScrollHeight();

	// Store the callback so that it can be used when the modal is closed
	$(divActiveModal).data ("closeCallback", modalOptions.closeCallback);
	$(divActiveModal).data ("persistOnClose", modalOptions.persistOnClose);

	// Here we might have validition somewhere on the page cancel it all
	if ($(window).validationEngine)
	{
		$(window).validationEngine('hideAll');
	}

	if (navigator.appVersion.indexOf("MSIE") != -1)
	{
		scrollHeight = $(window).scrollTop() + scrollHeight;
	}

	$("#backgroundPopup").addClass ('modalBackgroundPopup');

	if ($("#backgroundPopup").is (":hidden"))
	{
		$("#backgroundPopup").css (
		{
			"min-height" : scrollHeight - $(divActiveModal)[0].offsetHeight + "px"
		});
	}

	// Here need to check if a vertical scroll bar exists
	var modalTop = (middleoflocation) - (popupHeight/2+modalOptions.offsetTops);
	if (options.top)
	{
		modalTop = options.top;
	}

	$(divActiveModal).addClass('modalDivParent');
	var modalLeft = (windowWidth/2) - (popupWidth/2+modalOptions.offsetSides);

	$(divActiveModal).css (
	{
		"top" : modalTop,
		"left" : modalLeft
	});

	if (modalList.length > 1)
	{
		$(modalList[modalList.length - 1]).css (
		{
			"z-index" : HighestZIndex() + 2
		});
	}

	var activeModalZIndex = "";
	if (window.getComputedStyle)
	{
		activeModalZIndex = document.defaultView.getComputedStyle($(divActiveModal)[0], null).getPropertyValue ('z-index');
	}
	else if ($(divActiveModal)[0].currentStyle)
	{
		activeModalZIndex = $(divActiveModal)[0].currentStyle['z-index'];
	}

	// Now show the modal

	if (!options.hideBackground)
	{
		$("#backgroundPopup").css (
		{
			"z-index" : activeModalZIndex - 1
		});

		$("#backgroundPopup").show();
	}

	$(divActiveModal).addClass(modalOptions.className);
	$(divActiveModal).show();
	$(window).ResetModal();

	// Extra bind functions
	$(closeId).on ('click', function()
	{
			$(this).CloseModalWindow(divActiveModal);
			if (typeof (window.closeFunction) == 'function')
			{
				window.closeFunction();
			}
	});

	// Make the modal draggable
	if ($(divActiveModal).draggable)
	{
		$(divActiveModal).draggable({handle: '.modalHeader', containment: 'document'});
	}

	// Setup form compare for forms in each modal
	var formObject = new Array();
	$(divActiveModal).find ('form').each (function()
	{
		var arrayIndex = 0;

		$(this).find (':input').each (function()
		{
			var formObjectName = this.id;
			if (!formObjectName)
			{
				formObjectName = this.name;
			}

			switch(this.type)
			{
				case 'select-multiple':
				case 'select-one':
				case 'password':
				case 'textarea':
				case 'hidden':
				case 'text':
				case 'file':
					formObject[arrayIndex] = {id: formObjectName, value: $(this).val(), type: "string"};
					break;
				case 'checkbox':
				case 'radio':
					formObject[arrayIndex] = {id: formObjectName, value: $(this).prop ("checked"), type: "boolean"};
					break;
				default:
			}
			arrayIndex++;
		});
	});
	$(divActiveModal).data ("formValues", formObject);

	// Set the default element
	var thisDefaultInputElement = parseInt (modalOptions.defaultInputElement);
	if (thisDefaultInputElement >= 0)
	{
		$(divActiveModal).data ("currentKeyIndex", modalOptions.defaultInputElement);

		if (typeof($(divActiveModal +" input:enabled:visible")[modalOptions.defaultInputElement]) != 'undefined')
		{
			$(divActiveModal +" input:enabled:visible")[modalOptions.defaultInputElement].focus();
		}
	}

	// On element click set the currentKeyIndex to the element's index
	$(divActiveModal +" input:enabled:visible").on ('click', function()
	{
		$(divActiveModal).data ("currentKeyIndex", $(divActiveModal +" :input:enabled:visible").index (this));
	});

	// Here we bind key press events to the current modal
	$(divActiveModal +" input:enabled:visible").on ('keydown', function(event)
	{
		// Store the number of child input elements
		var modalFormElements = $(divActiveModal).data ("modalFormInput",
		{
			modalFormInputLength: $(divActiveModal).find ("form").find ("input:enabled:visible, select:enabled:visible").length
		});

		var indexOfInputElement = $(divActiveModal +" :input:enabled:visible").index (this);
		$(divActiveModal).data ("currentKeyIndex", indexOfInputElement);
		var parentLength = (modalFormElements.data("modalFormInput").modalFormInputLength - 1);

		if (event.keyCode == 9) //TAB
		{
			if ($(divActiveModal).data ("currentKeyIndex") < parentLength)
			{
				$(divActiveModal).data ("currentKeyIndex", (indexOfInputElement + 1));
			}
			else
			{
				// Here we lost focus on the form, so focus on the first input element
				$(divActiveModal).data ("currentKeyIndex", 0);
				$(divActiveModal +" input:enabled:visible")[0].focus();

				// Supress the TAB keypress event
				return false;
			}
		}
	});

	$(document).on ('keyup', function (event)
	{
		if (event.keyCode == 27) //ESCAPE
		{
			var divActiveModal = modalList[modalList.length - 1];

			if (!$(divActiveModal).data ("ignoreEscAlways"))
			{
				$(this).CloseModalWindow (divActiveModal);
				event.stopImmediatePropagation();
			}
		}
	});
};



$.fn.CloseModalWindow  = function (divModal)
{
	var closeCallbackFunction;

	if ($(window).validationEngine)
	{
		$(this).validationEngine('hideAll');
	}

	//first check if there isnt a overfirst as this takes over everything
	if ($.inArray('#overviews', modalList) > 0)
	{
		//here we have help rather close help and exit
		$(divModal).hide();
		modalList.splice ($.inArray('#overviews', modalList),1);
		modalList.splice ($.inArray('pageHelpModal', modalList),1);
		return false;
	}

	// Remove modal from the stack of active modals
	var index = modalList.indexOf (divModal);
	if (index > -1)
	{
		modalList.splice (index, 1);
		$(divModal).hide();
		closeCallbackFunction = $(divModal).data ("closeCallback");
	}


	//Clear modal form fields
	if ($(divModal).data ("persistOnClose") == false)
	{
		$(divModal).find ('form').each (function()
		{
			var inputArrayData = $(divModal).data ("formValues");

			$(this).find (':input').each (function()
			{
				for (var i = 0; i < inputArrayData.length; ++i)
				{
					if (inputArrayData[i] && this.id == inputArrayData[i].id)
					{
						switch (inputArrayData[i].type)
						{
							case 'string':
								$(this).val (inputArrayData[i].value);
								break;
							case 'boolean':
								$(this).attr ("checked", inputArrayData[i].value);
								break;
							default:
						}
					}
				}
			});
		});
	}

	var elem = $(divModal).find ('.uploadGhostButton');
	elem.replaceWith (elem.clone (true));

	$(divModal).find('.inlineNotification').each (function()
	{
		var notificationContainerId = this.id;
		$("#" + notificationContainerId).inlineNotification ("hide", {"delayTime": ""});
	});

	// Clean up the values bound to the modal's jQuery data object
	$(divModal).removeData ("closeCallback");
	$(divModal).removeData ("currentKeyIndex");
	$(divModal).removeData ("modalFormInput");

	// Show the next modal in the modal list
	if (modalList.length > 0)
	{
		$(modalList[(modalList.length)-1]).show();
		var divNextActiveModal = modalList[(modalList.length) - 1];

		// Force the next active modal to focus on the correct input element
		if ($(divNextActiveModal).data ("currentKeyIndex") >= 0)
		{
			var zIndex = document.defaultView.getComputedStyle ($(divNextActiveModal)[0], null).getPropertyValue ("z-index");
			$("#backgroundPopup").css(
			{
				"z-index" : zIndex - 1
			});
		}
	}

	// If no other modals are open close the modal background
	if (modalList.length == 0)
	{
		$("body").removeClass('backgroundPopUpView');
		$("#backgroundPopup").hide();
		$("#popupContact").hide();

		// Here we need to unbind the keyup event on the body
		$('body').off ('keyup');
	}

	if (typeof (closeCallbackFunction) == 'function')
	{
		closeCallbackFunction();
	}
};



// Export
if (typeof module !== 'undefined' && module.exports)
{
	module.exports.modalList = modalList;
}

